import React from "react";

export default function Oferta({exibeSaudacao, nomeCliente, exibePlano}) {
  return (
    <div className="w-full bg-[#002198]">
      <div className="text-white md:text-center controle-tamanho-tela md:px-0">
        <div className="flex items-center justify-center ">
          <img
            src="/assets/banner.png"
            alt="Tim Controle. O único com os melhores conteúdos à sua escolha."
            className="w-full h-auto mx-auto max-w-2xl text-white"
          />
        </div>
      </div>
    </div>
  );
}
