import {QuestionMarkCircleIcon} from "@heroicons/react/24/solid";
import React from "react";
import {useRecoilState} from "recoil";
import {exibirDuvidasState} from "../atoms/exibirDuvidasAtom";
import PassosCarrinho from "./carrinho/PassosCarrinho";

export default function Topo({exibeAjuda}) {
  const [exibirDuvidas, setExibirDuvidas] = useRecoilState(exibirDuvidasState);

  const handleExibirDuvidas = () => {
    setExibirDuvidas(1);
  };
  return (
    <div
      id="topo"
      className="fixed top-0 left-0 z-30 flex flex-row justify-center w-full bg-[#002198]"
    >
      <div className="flex flex-row items-center justify-between w-full controle-tamanho-tela">
        <img
          id="topo-logo"
          src="/assets/tim-logo.png"
          alt="Logo TIM"
          className="h-3 my-4 ml-4 text-white md:h-6 "
        />
        <PassosCarrinho />
        <div className="relative mr-4">
          {exibirDuvidas !== null && (
            <QuestionMarkCircleIcon
              onClick={handleExibirDuvidas}
              className="text-white cursor-pointer w-7"
            />
          )}{" "}
        </div>
      </div>
    </div>
  );
}
