const server = process.env.REACT_APP_ENDPOINT_API;
const serverApiViaCep = process.env.REACT_APP_API_CEP;
export async function consultaCEPMyTracking(cep) {
  const response = await fetch(`${server}/entregas/consulta-cep/${cep}`, {
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch((e) => {
    console.error(e);
    return null;
  });

  if (response?.body) {
    return await response.json();
  }

  return null;
}

export async function consultaCEPViaCep(cep) {
  const response = await fetch(serverApiViaCep + "/" + cep + "/json/").catch(
    (e) => {
      console.error(e);
      return null;
    },
  );

  if (response?.body) {
    return await response.json();
  }

  return null;
}
