import {Analytics} from "@vercel/analytics/react";
import {useEffect} from "react";
import {withCookies} from "react-cookie";
import {Routes, Route} from "react-router-dom";
import {useRecoilState} from "recoil";
import {exibirDuvidasState} from "./atoms/exibirDuvidasAtom";

import Duvidas from "./components/Duvidas";
import Topo from "./components/Topo";
import Carrinho from "./pages/Carrinho";
import Identificacao from "./pages/Identificacao";
import Planos from "./pages/Planos";
import {WhatsApp} from "./components/Flutuantes";
import Rodape from "./components/Rodape";
import FecharPedido from "./components/carrinho/FecharPedido";

function App() {
  const [exibirDuvidas, setExibirDuvidas] = useRecoilState(exibirDuvidasState);

  useEffect(() => {
    if (exibirDuvidas === 1) {
      document.querySelector(".div-duvidas").scrollIntoView({
        behavior: "smooth",
      });
      setExibirDuvidas(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exibirDuvidas]);

  return (
    <div className="w-screen min-h-screen overflow-x-hidden bg-[#002198] pt-11 ">
      <Topo />
      <Routes>
        <Route path="/" element={<Identificacao />} />
        <Route path="/carrinho/pedido/*" element={<FecharPedido />} />
        <Route path="/carrinho/*" element={<Carrinho />} />
        <Route path="/ofertas" element={<Planos />} />
        {/* <Identificacao /> */}
        {/* <Carrinho /> */}
      </Routes>
      {/* Duvidas */}
      {exibirDuvidas !== null && <Duvidas />}
      {/* <WhatsApp /> */}
      <Rodape />
      <Analytics />
    </div>
  );
}

export default withCookies(App);
