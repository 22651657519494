import React from "react";

function Rodape() {
  return (
    <div className=" controle-tamanho-tela">
      <div className="flex flex-wrap py-3 pl-5 gap-8 text-xs font-medium items-center text-white">
        <div className="flex flex-col py-3 pl-5 space-y-1 text-xs font-medium text-white">
          {/* <img className="inline mb-1 w-44" src="/assets/blackrock.png" alt="Parceiro oficial de vendas TIM" /> */}
          <p>Parceiro oficial de vendas TIM</p>
          <p>Blackrock Informática Ltda</p>
          <p>19.510.537/0001-20</p>
          <p className="pt-2 pr-2 max-w-xs font-normal">
            A BLACKROCK DIGITAL TRANSFORMATION não é parceira, afiliada,
            subsidiária ou de qualquer forma conectada ou relacionada a
            BLACKROCK, INC., a empresa global de gestão de ativos.
          </p>
        </div>
        <div className="flex flex-col text-xs pl-5 space-y-2 font-medium">
          <a
            className="cursor-pointer hover:underline"
            href="/politicas/politica-privacidade.pdf"
          >
            - Política de Privacidade
          </a>
          <a
            className="cursor-pointer hover:underline"
            href="/politicas/politica-cookies.pdf"
          >
            - Política de Cookies
          </a>
          <a
            className="cursor-pointer hover:underline"
            href="/politicas/termos-uso.pdf"
          >
            - Termos de Uso
          </a>
        </div>
      </div>
    </div>
  );
}

export default Rodape;
